"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefBase = void 0;
var SimpleEmitter_1 = require("./Tools/SimpleEmitter");
var Ref_1 = require("./Ref");
var RefBase = /** @class */ (function () {
    function RefBase() {
        this.Name = "";
        this.Id = -1;
        // public watchers: RefComponentBase<any, any>[] = []; // TODO: Component type?
        this.watchers = []; // TODO: type? RefComponentBase<any, any> sie średnio nadaje bo takie rzeczy jak CollectionView też tu wpadają
        this.onChangeHandler = new SimpleEmitter_1.SimpleEmitter(); // TODO: zamienić na HandlersCollection
        Ref_1.Ref.Id += 1;
        this.Id = Ref_1.Ref.Id;
    }
    RefBase.prototype.NameIt = function (name) {
        this.Name = name;
        return this;
    };
    RefBase.prototype.AddSubscriber = function (control) {
        this.watchers.push(control);
    };
    RefBase.prototype.RemoveControl = function (control) {
        this.watchers.Remove(control);
    };
    RefBase.prototype.RefreshSubscribers = function (except) {
        this.watchers
            .filter(function (c) { return c !== except; })
            .forEach(function (c) {
            c.Refresh();
        });
    };
    RefBase.prototype.ClearHandlers = function () {
        this.onChangeHandler.Clear();
        return this;
    };
    RefBase.prototype.OnChange = function (handler, callThisCallbackRightAway) {
        if (callThisCallbackRightAway === void 0) { callThisCallbackRightAway = false; }
        this.onChangeHandler.AddHandler(handler);
        if (callThisCallbackRightAway == true) {
            handler(this._value, true);
        }
        return this;
    };
    RefBase.Id = 0;
    return RefBase;
}());
exports.RefBase = RefBase;
