import { Div, Label, MultilineInput, Snack, Span, TextInput } from "@tblabs/truffle";
import { RecruitRouter } from "../Services/RecruitRouter";
import { Section } from "../Components/Section";
import { ApplyForm, OffersRepo, Recruit } from "../Models/OffersRepo";
import { Center } from "../Components/Center";
import { DangerButton } from "../Components/DangerButton";
import { v4 as uuidv4 } from 'uuid';


export class OfferApply extends Div
{
    constructor(private _repo: OffersRepo, private _router: RecruitRouter, private offerId: string)
    {
        super();
    }

    protected async OnAppend(): Promise<void>
    {
        const offer = await this._repo.GetOneOffer(this.offerId);

        if (!offer)
        {
            throw new Error(`No offer`);
        }

        const recruit = new Recruit();

        this.Append(
            new Span(`Aplikacja na stanowisko`).FontSize(28),
            new Label(offer.Title).FontSize(32).Bold().MarginLeft(8),
            `\n\nOdpowiedz na poniższe pytania w najbardziej zwięzły sposób w jaki potrafisz`,
            (offer.Form.Count == 0) && new Center("Brak pytań"),
            ...offer.Form.Items
                .map(x => new Section(x.Question.value, [
                    new MultilineInput(x.Answer).Rows(6).Placeholder("Miejsce na Twoją odpowiedź")
                ])),
            new Section("Twoje dane kontaktowe",
                [
                    new TextInput(recruit.Name).Placeholder("Imię"),
                    new TextInput(recruit.Phone).Placeholder("Telefon").MarginTop(8),
                    new TextInput(recruit.Email).Placeholder("Email").MarginTop(8),
                ]
            ),
            new Center(
                new DangerButton("Wyślij!").OnClick(async (btn) =>
                {
                    if (!recruit.Email.value)
                    {
                        new Snack("Brak danych kontaktowych!", "Wymagany jest chociaż email")
                        return;
                    }

                    const applyForm = new ApplyForm(uuidv4());
                    applyForm.Position = offer.Title.value;
                    applyForm.Answers = offer.Form;
                    applyForm.Recruit = recruit;

                    await this._repo.AddApplyForm(applyForm);
                    new Snack("Aplikacja wysłana!", "Dziękujemy 🩷");
                    btn.Disabled();
                })
            ).MarginTop(32)
        )
    }
}
