"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Sorter = void 0;
var Ref_1 = require("../Ref");
var Sorter = /** @class */ (function () {
    function Sorter(collection, selected) {
        if (selected === void 0) { selected = new Ref_1.Ref(""); }
        var _this = this;
        this.collection = collection;
        this.selected = selected;
        this.sorts = [];
        this.selected.OnChange(function (_) {
            _this.Refresh();
        }); // second parameter of OnChange will not take action here cause sort functions are not registered (with Add) at this point yet
    }
    Sorter.prototype.Refresh = function () {
        var _this = this;
        this.collection.Call(function (items) { return _this.SortBySelectedFunction(items); });
        return this;
    };
    Sorter.prototype.Add = function (label, sortFunction) {
        this.sorts.push({ label: label, sortFunction: sortFunction });
        return this;
    };
    Sorter.prototype.Reset = function () {
        this.selected.Restore();
        return this;
    };
    Object.defineProperty(Sorter.prototype, "SelectedValue", {
        get: function () {
            return this.selected;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Sorter.prototype, "Labels", {
        get: function () {
            return this.sorts.map(function (x) { return x.label; });
        },
        enumerable: false,
        configurable: true
    });
    Sorter.prototype.SortBySelectedFunction = function (items) {
        var _this = this;
        var _a;
        var sortingFunction = (_a = this.sorts.find(function (x) { return x.label === _this.selected.value; })) === null || _a === void 0 ? void 0 : _a.sortFunction;
        if (sortingFunction)
            return items.sort(sortingFunction);
        else
            return items;
    };
    return Sorter;
}());
exports.Sorter = Sorter;
