"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefObject = void 0;
var RefBase_1 = require("./RefBase");
var RefObject = /** @class */ (function (_super) {
    __extends(RefObject, _super);
    function RefObject(_obj) {
        var _this = _super.call(this) || this;
        _this._obj = _obj;
        return _this;
    }
    RefObject.prototype.Set = function (obj, excludeControlForRefresh) {
        this._obj = obj;
        this.RefreshSubscribers(excludeControlForRefresh);
        this.onChangeHandler.CallHandlers(this._obj);
    };
    RefObject.prototype.ForceOnChange = function () {
        this.RefreshSubscribers();
        this.onChangeHandler.CallHandlers(this._obj);
    };
    Object.defineProperty(RefObject.prototype, "object", {
        get: function () {
            return this._obj;
        },
        set: function (object) {
            this.Set(object);
        },
        enumerable: false,
        configurable: true
    });
    ///////////////// CZY TA FUNKCJAMA JAKIŚ SENS W OGÓLE????????????????
    // public Modify(fn: (obj: T) => T): this
    // {
    //     this._obj = fn(this._obj);
    //     this.emitter.CallHandlers(this._obj);
    //     this.RefreshSubscribers();
    //     return this;
    // }
    RefObject.prototype.Call = function (fn) {
        fn(this._obj);
        this.onChangeHandler.CallHandlers(this._obj);
        this.RefreshSubscribers();
        return this;
    };
    RefObject.prototype.OnChange = function (callback) {
        this.onChangeHandler.AddHandler(callback);
        return this;
    };
    RefObject.prototype.toString = function () {
        if (this.Name)
            return "RefObject named ".concat(this.Name);
        else
            return "RefObject";
    };
    RefObject.prototype.Storable = function (key, storage) {
        if (storage === void 0) { storage = "local"; }
        if (!key) {
            return this;
        }
        var stor;
        switch (storage) {
            case "local":
                stor = window.localStorage;
                break;
            case "session":
                stor = window.sessionStorage;
                break;
        }
        var value = JSON.parse(stor.getItem(key));
        this.Set(value);
        this.OnChange(function (v) { return stor.setItem(key, JSON.stringify(v)); });
        return this;
    };
    return RefObject;
}(RefBase_1.RefBase));
exports.RefObject = RefObject;
