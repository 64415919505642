"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionPaginator = exports.CollectionPaginatorOptions = void 0;
var RefNumber_1 = require("../../Core/RefNumber");
var BasicPaginator_1 = require("./BasicPaginator");
var CollectionPaginatorOptions = /** @class */ (function () {
    function CollectionPaginatorOptions() {
        this.PerPage = [10, 30, 100];
        this.PerPageStorageKey = "";
    }
    return CollectionPaginatorOptions;
}());
exports.CollectionPaginatorOptions = CollectionPaginatorOptions;
var CollectionPaginator = /** @class */ (function (_super) {
    __extends(CollectionPaginator, _super);
    function CollectionPaginator(collectionView, options) {
        if (options === void 0) { options = new CollectionPaginatorOptions(); }
        var _this = this;
        var start = new RefNumber_1.RefNumber(0);
        var count = new RefNumber_1.RefNumber(options.PerPage[0] || 10).Storable(options === null || options === void 0 ? void 0 : options.PerPageStorageKey);
        var total = collectionView.Collection.CountRef;
        var filtered = collectionView.FilteredCount;
        collectionView
            .See(start, count)
            .Refresh();
        _this = _super.call(this, start, count, total, filtered) || this;
        _this.AddGoToFirstButton();
        _this.AddGoToPreviousButton();
        _this.AddDisplay();
        _this.AddGoToNextButton();
        _this.AddGoToLastButton();
        _this.AddPagesCountSwitch(options.PerPage);
        return _this;
    }
    return CollectionPaginator;
}(BasicPaginator_1.BasicPaginator));
exports.CollectionPaginator = CollectionPaginator;
