"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RefSerializer = void 0;
var RefDeflattener_1 = require("./Deflattener/RefDeflattener");
var RefFlattener_1 = require("./Flattener/RefFlattener");
var RefSerializer = /** @class */ (function () {
    function RefSerializer() {
    }
    RefSerializer.Serialize = function (source) {
        var flat = RefFlattener_1.RefFlattener.Flat(source);
        return JSON.stringify(flat);
    };
    RefSerializer.Deserialize = function (source, template) {
        var sourceAsObject = JSON.parse(source);
        var target = RefDeflattener_1.RefDeflattener.Deflat(sourceAsObject, template);
        return target;
    };
    RefSerializer.Flatenize = function (source) {
        var flat = RefFlattener_1.RefFlattener.Flat(source);
        return flat;
    };
    RefSerializer.Deflatenize = function (source, template) {
        var target = RefDeflattener_1.RefDeflattener.Deflat(source, template);
        return target;
    };
    return RefSerializer;
}());
exports.RefSerializer = RefSerializer;
